import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const IndicatorContainer = styled.div`
  position: absolute;
  top: -6px;
  left: -11px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${C.v2Theme.borderRadius.borderRadius2};
  background: ${C.v2Theme.backgroundColors.black};
  font-family: ${C.baseFontFamily};
  color: ${C.v2Theme.fontColors.white};
  font-size: ${C.v2Theme.fontSizes.caption};
  font-weight: ${C.v2Theme.fontWeights.semiBold};
`
IndicatorContainer.displayName = "S.IndicatorContainer"
