import { Icon } from "@components/atoms/Icon"
import Indicator from "@components/atoms/Indicator/Indicator"
import React, { useEffect } from "react"

import * as S from "./styles"
import { MenuActionProps } from "./types"

const MenuAction: React.FC<MenuActionProps> = ({
  iconName,
  title,
  onClick,
  indicatorCount,
}) => {

  // We are using a state here and using it to conditionally render the indicator
  // This is because the indicatorCount is not available when the component is rendered on the server
  // if we use the indicatorCount directly, the first render on client side will not match the server side render
  // and will cause a hydration mismatch
  // Refer: https://github.com/vercel/next.js/discussions/17443
  const [count, setCount] = React.useState<string | number | undefined>()

  useEffect(() => {
    setCount(indicatorCount)
  }, [indicatorCount])

  return (
    <S.MenuActionContainer onClick={onClick}>
      {count ? <Indicator>{count}</Indicator> : null}
      <Icon iconName={iconName} size={24} color={""} />
      <label>{title}</label>
    </S.MenuActionContainer>
  )
}

export default MenuAction
