import { DropdownBodyAlign } from "@components/atoms/DropdownBody/types"
import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const DropdownBodyContainer = styled.div<{
  bodyAlign: DropdownBodyAlign
  topMargin: number
  shift: number
}>`
  ${(props) => `
    position: absolute;
    top: ${props.topMargin}px;
    ${props.bodyAlign}: ${props.shift}px;
    z-index: 2;
    min-width: 172px;
    
    span {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid white;
        position: absolute;
        top: 0;
        ${props.bodyAlign}: 28px;
      }
    
  `}
`
DropdownBodyContainer.displayName = "S.DropdownBodyContainer"

export const DropDownOptionsWrapper = styled.div<{ paddingValue: string }>`
  ${(props) => `
    background: ${C.v2Theme.gradients.gradients2};
    box-shadow: ${C.v2Theme.shadows.shadow1};
    padding: ${props.paddingValue};
    margin: 8px 0 0 0;
    border-radius: 8px;
    cursor: pointer;
  `}
`
DropDownOptionsWrapper.displayName = "S.DropDownOptionsWrapper"

export const DropDownOption = styled.div`
  font-family: ${C.baseFontFamily};
  color: ${C.v2Theme.fontColors.gray1};
  font-size: ${C.v2Theme.fontSizes.paragraph1};
  font-weight: ${C.v2Theme.fontWeights.regular};
  margin: 24px 0;
`
DropDownOption.displayName = "S.DropDownOption"
