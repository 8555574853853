import { RefObject, useEffect, useRef } from "react"

const defaultEvents = ["mousedown", "touchstart"]

function on<T extends Window | Document | HTMLElement | EventTarget>(
  object: T | null,
  ...arguments_:
    | Parameters<T["addEventListener"]>
    | [string, Function | null, ...any]
): void {
  if (object && object.addEventListener) {
    object.addEventListener(
      ...(arguments_ as Parameters<HTMLElement["addEventListener"]>)
    )
  }
}

function off<T extends Window | Document | HTMLElement | EventTarget>(
  object: T | null,
  ...arguments_:
    | Parameters<T["removeEventListener"]>
    | [string, Function | null, ...any]
): void {
  if (object && object.removeEventListener) {
    object.removeEventListener(
      ...(arguments_ as Parameters<HTMLElement["removeEventListener"]>)
    )
  }
}

export const useClickedOutside = <E extends Event = Event>(
  reference: RefObject<HTMLElement | null>,
  onClickAway: (event: E) => void,
  events: string[] = defaultEvents
) => {
  const savedCallback = useRef(onClickAway)
  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])
  useEffect(() => {
    const handler = (event) => {
      const { current: element } = reference
      if (element && !element.contains(event.target)) {
        savedCallback.current(event)
      }
    }
    for (const eventName of events) {
      on(document, eventName, handler)
    }
    return () => {
      for (const eventName of events) {
        off(document, eventName, handler)
      }
    }
  }, [events, reference])
}
