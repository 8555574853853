import { SearchBarTheme } from "@components/atoms/SearchBar/types"
import styled from "styled-components"
import { ReactSVG } from "react-svg"
import * as C from "@temp/@next/globalStyles/constants"

const searchBar = {
  default: {
    background: C.v2Theme.backgroundColors.gray2,
    borderRadius: C.v2Theme.borderRadius.borderRadius2,
    label: {
      fontColor: C.v2Theme.fontColors.gray1,
      fontSize: C.v2Theme.fontSizes.paragraph2,
      fontWeight: C.v2Theme.fontWeights.regular,
    },
    padding: "0 38px",
    shadow: "none",
    value: {
      fontColor: C.v2Theme.fontColors.black,
      fontSize: C.v2Theme.fontSizes.paragraph1,
      fontWeight: C.v2Theme.fontWeights.regular,
    },
  },
  nav: {
    background: C.v2Theme.gradients.gradients2,
    borderRadius: C.v2Theme.borderRadius.borderRadius2,
    label: {
      fontColor: C.v2Theme.fontColors.gray1,
      fontSize: C.v2Theme.fontSizes.paragraph2,
      fontWeight: C.v2Theme.fontWeights.regular,
    },
    padding: "0 38px",
    shadow: C.v2Theme.shadows.shadow2,
    value: {
      fontColor: C.v2Theme.fontColors.black,
      fontSize: C.v2Theme.fontSizes.paragraph1,
      fontWeight: C.v2Theme.fontWeights.regular,
    },
  },
}

export const SearchBar = styled.div`
  .search-result-animation-enter {
    opacity: 0;
    transform: translateY(25%);
  }
  .search-result-animation-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  .search-result-animation-exit {
    opacity: 1;
  }
  .search-result-animation-exit-active {
    opacity: 0;
    transform: translateY(25%);
    transition: opacity 300ms, transform 300ms;
  }
  position: relative;
  width: 100%;
  z-index: 3;
`
SearchBar.displayName = "S.SearchBar"

export const SearchBarContent = styled.div<{ searchBarTheme: SearchBarTheme }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .close-button-animation-enter {
    svg {
      opacity: 0;
      transform: scale(0);
    }
  }
  .close-button-animation-enter-active {
    svg {
      opacity: 1;
      transform: scale(1);
      transition: opacity 300ms, transform 300ms;
    }
  }
  .close-button-animation-exit {
    svg {
      opacity: 1;
    }
  }
  .close-button-animation-exit-active {
    svg {
      opacity: 0;
      transform: scale(0);
      transition: opacity 300ms, transform 300ms;
    }
  }
`
SearchBarContent.displayName = "S.SearchBarContent"

export const SearchBarContentInput = styled.input.attrs(() => ({
  className: "header-search-input",
}))<{
  searchBarTheme: SearchBarTheme
}>`
  ${(props) => `
    border: none;
    height: 2rem;
    width: 100%;
    padding: ${searchBar[props.searchBarTheme].padding};
    border-radius: ${searchBar[props.searchBarTheme].borderRadius};
    background: ${searchBar[props.searchBarTheme].background};
    box-shadow: ${searchBar[props.searchBarTheme].shadow};
    font-family: ${C.baseFontFamily};
    
    color: ${searchBar[props.searchBarTheme].value.fontColor};
    font-size: ${searchBar[props.searchBarTheme].value.fontSize};
    font-weight: ${searchBar[props.searchBarTheme].value.fontWeight};
    
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${searchBar[props.searchBarTheme].label.fontColor};
      font-size: ${searchBar[props.searchBarTheme].label.fontSize};
      font-weight: ${searchBar[props.searchBarTheme].label.fontWeight};
  `}
`

SearchBarContentInput.displayName = "S.SearchBarContentInput"

export const SearchBarContentSearch = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  height: 16px;
  width: 16px;
`
SearchBarContentSearch.displayName = "S.SearchBarContentSearch"

export const SearchBarContentClose = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  height: 14px;
  width: 14px;
  cursor: pointer;
`
SearchBarContentClose.displayName = "S.SearchBarContentClose"
