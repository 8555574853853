import debounce from "lodash/debounce"
import { useRouter } from "next/router"
import isEmpty from "lodash/isEmpty"
import { parse } from "query-string"
import React, { useEffect, useMemo, useState } from "react"

import { ENTER_KEY } from "../../../../constants"
import * as S from "./styles"
import { SearchBarProps } from "./types"

const handleFocusGained = () => {
  const elements = document.querySelectorAll(".product-filters-mobile")
  for (const element of elements as any) {
    element.classList.add("product-filters-hide")
  }
}

const handleFocusBlurred = () => {
  const elements = document.querySelectorAll(".product-filters-mobile")
  for (const element of elements as any) {
    element.classList.remove("product-filters-hide")
  }
}

const handleKeyUp = (event: any) => {
  if (event.keyCode === ENTER_KEY) {
    event.target.blur()
  }
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  searchBarTheme = "default",
}) => {
  const router = useRouter()
  const { query: routerQuery, asPath, pathname } = router

  // Get the query parameters directly from URL since the router query will
  // not be populated at the time of component initial render
  const query = [asPath.split("?")[0]?.split("#")[0] , asPath.split("?")[1]?.split("#")[0]];
  let searchTerm = ""
  if (query[1]) {
    const parsed = parse(query[1])
    if (parsed && parsed.q) {
      searchTerm = parsed.q.toString()
    }
  }
  const [inputText, setInputText] = useState(searchTerm)

  const routeChanger = (value: string) => {
    if (value) {
      const parsed = parse(query[1], { sort: false })
      const queryForUrl = isEmpty(routerQuery) ? parsed : routerQuery
      queryForUrl.q = value

      // If the input is changed in the search page, simply update the URL
      if (pathname === "/search") {
        router.replace({ pathname: "/search", query: queryForUrl }, undefined, {
          shallow: true,
        })
        // Else route to the search page with updated URL
      } else {
        router.push(`${"/search"}?q=${value}`, undefined, { shallow: true })
      }
    } else {
      router.replace("/")
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRouteChanger = useMemo(() => debounce(routeChanger, 500), [])

  useEffect(() => {
    return () => {
      debouncedRouteChanger.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (value: string) => {
    setInputText(value)
    debouncedRouteChanger(value)
  }

  const handleInputCleared = () => {
    setInputText("")
    router.replace("/")
  }

  return (
    <S.SearchBar>
      <S.SearchBarContent searchBarTheme={searchBarTheme}>
        <S.SearchBarContentInput
          searchBarTheme={searchBarTheme}
          placeholder={placeholder}
          onChange={(event) => {
            changeHandler(event.target.value)
          }}
          onKeyUp={handleKeyUp}
          onFocus={handleFocusGained}
          onBlur={handleFocusBlurred}
          value={inputText}
          autoFocus={pathname === "/search"}
        />
        <S.SearchBarContentSearch src="/images/components/buycepslogo/search.svg" />
        {inputText && (
          <S.SearchBarContentClose
            src="/images/components/buycepslogo/search-close.svg"
            onClick={() => {
              handleInputCleared()
            }}
          />
        )}
      </S.SearchBarContent>
    </S.SearchBar>
  )
}
