import { DropdownBodyProps } from "@components/atoms/DropdownBody/types"
import React from "react"

import * as S from "./styles"

const paddingDefault = "6px 16px 6px 16px"

const DropdownBody: React.FC<DropdownBodyProps> = ({
  children,
  bodyAlign = "right",
  topMargin,
  padding = paddingDefault,
  shift = 0,
}) => {
  return (
    <S.DropdownBodyContainer
      bodyAlign={bodyAlign}
      topMargin={topMargin}
      shift={shift}
    >
      <S.DropDownOptionsWrapper paddingValue={padding}>
        <>
          <span />
          {children}
        </>
      </S.DropDownOptionsWrapper>
    </S.DropdownBodyContainer>
  )
}

export default DropdownBody
