import { gql } from "@apollo/client"
import { orderDetailFragment } from "@temp/@next/components/templates/OrderDetails/queries"
import { checkoutAddressFragment, checkoutFragment } from "../Checkout/queries"

const userFragment = gql`
  ${checkoutAddressFragment}
  fragment User on User {
    id
    email
    firstName
    lastName
    phone
    inferredEmail
    inferredFirstName
    inferredLastName
    inferredPhone
    isStaff
    emailVerified
    phoneVerified
    billingEmail
    billingPhone
    passwordPresent
    freshchatId
    gender
    dateOfBirth
    weight {
      unit
      value
    }
    height {
      unit
      value
    }
    dateOfBirth
    gender
    avatar {
      url
    }
    checkout {
      id
      token
    }
    defaultShippingAddress {
      ...Address
    }
    defaultBillingAddress {
      ...Address
    }
    addresses {
      ...Address
    }
  }
`

export const tokenAuthMutation = gql`
  ${userFragment}
  mutation TokenAuth(
    $username: UsernameInput
    $password: PasswordInput
    $accessToken: AccessTokenInput
    $referralCode: String
    $clickId: String
  ) {
    tokenCreate(
      username: $username
      password: $password
      accessToken: $accessToken
      affiliate: { referralCode: $referralCode, clickId: $clickId }
    ) {
      token
      errors {
        field
        message
      }
      isNewUser
      user {
        ...User
      }
    }
  }
`

export const tokenVerificationMutation = gql`
  ${userFragment}
  mutation VerifyToken($token: String!) {
    tokenVerify(token: $token) {
      payload
      user {
        ...User
      }
    }
  }
`

export const checkoutCustomerAttach = gql`
  ${checkoutFragment}
  mutation CheckoutCustomerAttach($checkoutId: ID!, $customerId: ID!) {
    checkoutCustomerAttach(checkoutId: $checkoutId, customerId: $customerId) {
      errors {
        field
        message
      }
      checkout {
        ...Checkout
      }
    }
  }
`
