import { gql } from "@apollo/client"

export const checkoutAddressFragment = gql`
  fragment Address on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    postalCode
    country {
      code
      country
    }
    countryArea
    phone
    email
  }
`

export const checkoutProductVariantFragment = gql`
  fragment ProductVariant on ProductVariant {
    id
    name
    isPublished
    isCodAvailable
    pricing {
      price {
        gross {
          amount
          localized
        }
        net {
          amount
          localized
        }
        currency
      }
      listPrice {
        gross {
          amount
          localized
        }
      }
      priceUndiscounted {
        gross {
          amount
          localized
        }
        currency
      }
    }
    images {
      id
      url
    }
    product {
      id
      productId: id
      name
      description
      firstImage {
        id
        url
        alt
      }
      category {
        id
        name
      }
      brand {
        id
        name
      }
    }
  }
`

const checkoutPriceFragment = gql`
  fragment Price on TaxedMoney {
    gross {
      amount
      localized
    }
    net {
      amount
      localized
    }
    currency
  }
`

const checkoutShippingMethodFragment = gql`
  fragment ShippingMethod on ShippingMethod {
    id
    name
    price {
      currency
      amount
      localized
    }
  }
`

const checkoutComboProductVariationFragment = gql`
  ${checkoutProductVariantFragment}
  fragment ComboProductVariation on ComboProductVariation {
    id
    pricing {
      price {
        gross {
          amount
        }
      }
    }
    stockQuantity
    comboProduct {
      id
      name
      description
      firstImage {
        id
        url
        alt
      }
    }
    variants {
      ...ProductVariant
    }
  }
`

const checkoutLineFragment = gql`
  ${checkoutPriceFragment}
  ${checkoutProductVariantFragment}
  ${checkoutComboProductVariationFragment}
  fragment CheckoutLine on CheckoutLine {
    id
    quantity
    totalPrice {
      ...Price
    }
    price {
      ...Price
    }
    isFree
    isNearExpiry
    variant {
      stockQuantity
      neStockQuantity
      ...ProductVariant
    }
    variation {
      ...ComboProductVariation
    }
    quantity
  }
`

export const checkoutFragment = gql`
  ${checkoutLineFragment}
  ${checkoutAddressFragment}
  ${checkoutPriceFragment}
  ${checkoutShippingMethodFragment}
  fragment Checkout on Checkout {
    id
    refereeDiscount
    referralCode
    availablePaymentGateways
    token
    user {
      id
      email
      phone
    }
    vouchers {
      id
      type
      name
      applyOncePerOrder
      code
      usageLimit
      used
      discountValueType
      startDate
      endDate
      discountValue
      termsAndConditions
      applicability {
        isApplicable
        reason
      }
      description
    }
    totalPrice {
      ...Price
    }
    subtotalPrice {
      ...Price
    }
    billingAddress {
      ...Address
    }
    shippingAddress {
      ...Address
    }
    email
    phone
    availableShippingMethods {
      ...ShippingMethod
    }
    shippingMethod {
      ...ShippingMethod
    }
    onlineShippingPrice {
      gross {
        amount
        localized
      }
    }
    codShippingPrice {
      gross {
        amount
        localized
      }
    }
    shippingPrice {
      ...Price
    }
    lines {
      ...CheckoutLine
    }
    voucherCode
    discountName
    discountAmount {
      amount
      localized
    }
    canDeliver
  }
`

export const getCheckoutQuery = gql`
  ${checkoutFragment}
  query getCheckout($token: UUID!) {
    checkout(token: $token) {
      ...Checkout
    }
  }
`

export const updateCheckoutLineQuery = gql`
  ${checkoutLineFragment}
  ${checkoutPriceFragment}
  ${checkoutFragment}
  mutation updateCheckoutLine($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {
      checkout {
        ...Checkout
      }
      errors {
        field
        message
      }
    }
  }
`

export const createCheckoutMutation = gql`
  ${checkoutFragment}
  mutation createCheckout($checkoutInput: CheckoutCreateInput!) {
    checkoutCreate(input: $checkoutInput) {
      errors {
        field
        message
      }
      checkout {
        ...Checkout
      }
    }
  }
`

export const getUserCheckoutQuery = gql`
  ${checkoutFragment}
  query getUserCheckout {
    me {
      id
      checkout {
        ...Checkout
      }
    }
  }
`

export const getPaymentStatusQuery = gql`
  query PaymentStatus($token: String!) {
    payment(token: $token) {
      id
      chargeStatus
      order {
        id
      }
    }
  }
`

export const addCheckoutUpdateVoucherMutation = gql`
  ${checkoutPriceFragment}
  ${checkoutFragment}
  mutation CheckoutUpdateVoucher($checkoutId: ID!, $voucherCode: String!) {
    checkoutUpdateVoucher(checkoutId: $checkoutId, voucherCode: $voucherCode) {
      checkout {
        ...Checkout
      }
      errors {
        field
        message
      }
    }
  }
`

export const removeCheckoutUpdateVoucherMutation = gql`
  ${checkoutPriceFragment}
  ${checkoutFragment}
  mutation CheckoutRemoveVoucher($checkoutId: ID!) {
    checkoutUpdateVoucher(checkoutId: $checkoutId) {
      checkout {
        ...Checkout
      }
      errors {
        field
        message
      }
    }
  }
`

export const userAddReferralCodeMutation = gql`
  mutation UserAddReferralCode($referralCode: String!) {
    userAddReferralCode(referralCode: $referralCode) {
      user {
        id
        firstName
      }
      errors {
        field
        message
      }
    }
  }
`
