// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReactiveVar } from "@apollo/client"
import { userGlobalState } from "@app/globalStates/User/userGlobalState"
import { logout } from "@temp/@next/globalStates/User/UserGlobal"
import { OverlayContext, OverlayTheme, OverlayType } from "@temp/components/Overlay"
import Link from "@temp/core/link"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { useClickedOutside } from "@temp/hooks/useClickedOutside"
import { getMedia } from "@temp/localStates/media"
import {
  accountUrl,
  addressBookUrl,
  orderHistoryUrl,
} from "@temp/userAccount/routes"
import { signOut } from "next-auth/client"
import React, { useContext, useRef, useState } from "react"
import { useAlert } from "react-alert"

import DropdownBody from "../../atoms/DropdownBody/DropdownBody"
import MenuAction from "../../atoms/MenuAction/MenuAction"
import { Spacer } from "../../atoms/Spacer"
import {
  MainMenuDesktopAccountLinks,
  MainMenuDesktopAccountWrapper,
} from "../MainMenu/styles"

export const MainMenuAccountWrapper: React.FC = () => {
  // reactive var
  // Re render this component when user changes
  const { user, loading } = useReactiveVar(userGlobalState)
  // Re render this component when matches changes
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)

  // state
  // Re render this component to show dropdown
  const [showDropdown, setShowDropdown] = useState(false)

  // custom hook
  const alert = useAlert()

  // context
  const overlayContext = useContext(OverlayContext)

  const title = matches.small ? "" : user ? "Account" : "Login"

  const menuReference = useRef(null)
  // functions
  const handleLogoutClicked = async () => {
    setShowDropdown(false)
    alert.show(
      {
        title: "Logging out...",
      },
      { type: "success" }
    )
    if (user) {
      logout(alert,user)
    }
    await signOut({ redirect: false })
  }

  const handleLinkClick = () => {
    setShowDropdown(false)
  }

  const handleAccountClicked = () => {
    if (user) {
      setShowDropdown(!showDropdown)
    } else if (!user && !loading) {
      overlayContext.show(
        OverlayType.login,
        matches.small ? OverlayTheme.bottom : OverlayTheme.right,
        {}
      )
    }
  }

  useClickedOutside(menuReference, () => {
    setShowDropdown(false)
  })

  return (
    <MainMenuDesktopAccountWrapper
      ref={menuReference}
      onMouseEnter={() => !matches.small && user && setShowDropdown(true)}
      onMouseLeave={() => !matches.small && setShowDropdown(false)}
    >
      <MenuAction iconName="account" title={title} onClick={handleAccountClicked} />
      {showDropdown ? (
        <DropdownBody
          bodyAlign={matches.small ? "right" : "left"}
          topMargin={matches.small ? 28 : 24}
          padding="0"
          shift={matches.small ? -16 : 0}
        >
          <ul>
            <MainMenuDesktopAccountLinks onClick={() => handleLinkClick()}>
              <Link prefetch={false} href={accountUrl}>
                <a>Account</a>
              </Link>
            </MainMenuDesktopAccountLinks>
            <Spacer />
            <MainMenuDesktopAccountLinks onClick={() => handleLinkClick()}>
              <Link prefetch={false} href={orderHistoryUrl}>
                <a>Order History</a>
              </Link>
            </MainMenuDesktopAccountLinks>
            <Spacer />
            <MainMenuDesktopAccountLinks onClick={() => handleLinkClick()}>
              <Link prefetch={false} href={addressBookUrl}>
                <a>Address</a>
              </Link>
            </MainMenuDesktopAccountLinks>
            <Spacer />
            <MainMenuDesktopAccountLinks onClick={handleLogoutClicked}>
              Logout
            </MainMenuDesktopAccountLinks>
          </ul>
        </DropdownBody>
      ) : null}
    </MainMenuDesktopAccountWrapper>
  )
}
