import { IndicatorProps } from "@components/atoms/Indicator/types"
import React from "react"

import * as S from "./styles"

const Indicator: React.FC<IndicatorProps> = ({ children }) => {
  return <S.IndicatorContainer>{children}</S.IndicatorContainer>
}

export default Indicator
