import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const MenuActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  label {
    font-family: ${C.baseFontFamily};
    color: ${C.v2Theme.fontColors.black};
    font-size: ${C.v2Theme.fontSizes.paragraph1};
    font-weight: ${C.v2Theme.fontWeights.regular};
    margin-left: 4px;
    cursor: pointer;
  }
`
MenuActionContainer.displayName = "S.MenuActionContainer"
